<template>
	<div v-loading='loading'>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>快递助手</span>
			</div>
			<div>
				<!-- :rules="rules" -->
				<el-form :model="ruleForm"  ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="快递助手:">
						<el-radio-group v-model="ruleForm.IsUse" @change="changeRaio">
							<el-radio :label="true">开启</el-radio>
							<el-radio :label="false">关闭</el-radio>
						</el-radio-group>
					</el-form-item>
					<!--  -->
					<!-- <el-form-item label="APP_KEY" prop="AppKey" :rules="rules.AppKey" v-if="ruleForm.IsUse">
						<el-input v-model="ruleForm.AppKey" style="width:300px;" placeholder='请输入快递助手提供的APP_KEY'></el-input>
					</el-form-item>
					<el-form-item label-width='120px' label="APP_SECRET" prop="AppSecret" :rules="rules.AppSecret" v-if="ruleForm.IsUse">
						<el-input v-model="ruleForm.AppSecret" style="width:300px;" placeholder='请输入快递助手提供的APP_SECRET'></el-input>
					</el-form-item> -->
				</el-form>
				<div v-if="ruleForm.IsUse">
					<div style="font-size: 14px;margin-left:30px;" class="promptbox">
						<div>快递助手启用后，订单推送规则：</div>
						<div>1.系统每10分钟主动推送订单至快递助手</div>
						<div>2.仅推送待发货的快递发货单</div>
						<div>3.如果订单里有退款中的商品，将不推送</div>
						<div>4.若订单中商品退款成功，将扣减已经退款成功数量，推送可发货数量至快递助手</div>
						<div>5.若急需发货，可点击下方[立即推送订单]按钮</div>
					</div>
					<div class="expressBox" style="display: flex;margin-left:30px;margin-top: 30px;">
						<img src="http://cdn.dkycn.cn/Images/expressAssiantImg.png" style="width: 100px;height: 100px;" />
						<div class="expressBtnstyle">
							<div @click='EnterExpressAssiatant'>进入快递助手后台使用</div>
							<div @click='PushOrder' v-loading='isloading'>立即推送订单</div>
						</div>
					</div>
				</div>

			</div>
		</el-card>
		<!-- <div class="bottom-save-btn">
			<el-button type="primary" style="width:240px" @click="submitForm('ruleForm')" :loading="saveLoading">保存</el-button>
		</div> -->
	</div>


</template>

<script>
	import config from '@/config/index';
	import {
		kdzs_configIndex,
		kdzs_configSave,
		kdzs_configPushorder
	} from '@/api/TurnTomySelf.js';
	export default {
		data() {
			return {
				isloading: false,
				saveLoading: false,
				loading: false,
				ruleForm: {
					IsUse: false,
					AppKey: '',
					AppSecret: ''
				},

				// rules: {
				// 	AppKey: [{
				// 		required: true,
				// 		message: '请输入快递助手提供的APP_KEY',
				// 		trigger: 'blur'
				// 	}],
				// 	AppSecret: [{
				// 		required: true,
				// 		message: '请输入快递助手提供的APP_SECRET',
				// 		trigger: 'blur'
				// 	}]

				// },
				goUrls: config.GO_URL,
				errorMessage: '就是错误的',
				exportUrl: config.EXPORT_URL,
			}
		},
		created() {
			//初始化
			this.getExpressInit()
		},
		methods: {
			async changeRaio(){
				try{
					this.loading = true
					let data = {
						IsUse: this.ruleForm.IsUse,
						// AppKey: this.ruleForm.AppKey,
						// AppSecret: this.ruleForm.AppSecret
					}
					let result = await kdzs_configSave(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.getExpressInit()
						setTimeout(() => {
							this.loading = false
						}, 2000)
					}
				}catch(err){
					
				}finally{
					this.loading = false
				}
			},
			//推送订单
			async PushOrder() {
				try {
					this.isloading = true
					let result = await kdzs_configPushorder()
					if (result.IsSuccess) {
						this.isloading = false
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}
				} catch (err) {
					this.isloading = false
				} finally {
					// this.isloading = false
				}
			},
			async getExpressInit() {
				try {
					this.loading = true
					let result = await kdzs_configIndex()
					if (result.IsSuccess) {
						this.ruleForm = result.Result
					}
					// console.log(result,'初始化快递助手')
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			// 保存
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.saveLoading = true
							let data = {
								IsUse: this.ruleForm.IsUse,
								AppKey: this.ruleForm.AppKey,
								AppSecret: this.ruleForm.AppSecret
							}
							let result = await kdzs_configSave(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.getExpressInit()
								setTimeout(() => {
									this.saveLoading = false
								}, 2000)
							}

						} catch (e) {
							//TODO handle the exception
							console.log(e)
						} finally {

							this.saveLoading = false
						}


					} else {}
				});

			},
			EnterExpressAssiatant() {
				let url = this.exportUrl + '/pc/kdzs-config/jump'
				window.open(url)
			}
		}
	}
</script>

<style lang="less" scoped>
	.expressBox {
		align-items: center;
	}

	.promptbox {
		div {
			line-height: 25px;
		}
	}

	.bottom-save-btn {
		position: fixed;
		width: 100%;
		background: #fff;
		bottom: 0;
		text-align: center;
		padding: 20px 0;
		z-index: 100;
		box-shadow: 13px 1px 6px #999;
	}

	input[type=number]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	.expressBtnstyle {
		font-size: 14px;
		margin-left: 20px;

		div {
			width: 200px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			background: #F5F5F5;
			margin-bottom: 25px;
			cursor: pointer;

			&:last-child {
				margin-bottom: 0px
			}
		}
	}
</style>
